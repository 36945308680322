import { ArrowBack } from '@mui/icons-material';
import { Card, CardContent, CardHeader, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import axios from 'axios';
import Headerx from 'components/Header/Headerx';
import SoftBox from 'components/SoftBox';
import { CustomDescrypt } from 'global/func';
import { serverUrl } from 'global/variable';
import DetailTim from 'myComponent/DetailTim';
import MyTable from 'myComponent/Table/MyTable';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import userStore from 'store/userStore';
import Swal from 'sweetalert2';

const Detail = () => {
    const encryptUser = localStorage.getItem('AuthPersist');
    const encryptUserJson = JSON.parse(encryptUser);
    const decryptedUser = encryptUserJson.state.user === null ? "" : JSON.parse(CustomDescrypt(encryptUserJson.state.user));
    const logOut = userStore((state) => state.logOut)

    const location = useLocation();
    const navigate = useNavigate();
    const data = location.state;

    const [loading, setLoading] = useState(true)
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        // console.log(data);
        reqRekrut();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const reqRekrut = () => {
        setLoading(true)
        axios.post(
            serverUrl + '/vote-getter/dtd',
            {
                dtd_username: data.username,
            },
            {
                headers: {
                    'Authorization': `Bearer ${decryptedUser}`,
                    'content-type': 'multipart/form-data'
                }
            })
            .then((response) => {
                // console.log(response);
                setTableData(response.data)
                setLoading(false)
            })
            .catch((error) => {
                console.log(error);
                if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                    logOut();
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.response.status + ' ' + error.response.statusText
                    })
                }
                setLoading(false)
            })
    }

    const columns = [
        {
            accessorKey: 'nama',
            header: 'Nama'
        },
        {
            accessorKey: 'id_kec',
            header: 'ID_KEC'
        },
        {
            accessorKey: 'nama_kec',
            header: 'Kec',
            // filterVariant: 'select',
            // filterSelectOptions: listKec,
        },
        {
            accessorKey: 'id_kel',
            header: 'ID_KEL',
        },
        {
            accessorKey: 'nama_kel',
            header: 'Kel',
            // filterVariant: 'select',
            // filterSelectOptions: listKel,
        },
        {
            accessorKey: 'dtd_nama',
            header: 'Tim'
        },
    ]

    const columnVisibility = {
        id_kec: false,
        // nama_kec: false,
        id_kel: false,
        // nama_kel: false,
        // dtd_nama: false,
        // tgl_lahir: false
    }

    return (
        <>
            <Headerx title='Relawan Brigade' />
            <SoftBox mb={3}>
                <Grid container spacing={3} justifyContent='center' pt={5}>
                    <Grid item xs={12} sm={12} md={9}>
                        <Card>
                            <CardHeader
                                title={
                                    <Typography variant='h6'>Kembali</Typography>
                                }
                                avatar={
                                    <Tooltip title="Kembali">
                                        <IconButton variant="outlined" onClick={() => navigate(-1)}><ArrowBack /></IconButton>
                                    </Tooltip>
                                }
                            // subheader="September 14, 2016"
                            />
                            <CardContent>
                                <Grid container spacing={2} justifyContent='center'>
                                    <Grid item xs={12}>
                                        <DetailTim data={data} />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Grid container spacing={3} justifyContent='center' pt={3}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader
                                title={
                                    <Typography variant='h6'>Data Anggota</Typography>
                                }
                            // subheader="September 14, 2016"
                            />
                            <CardContent>
                                <MyTable
                                    data={tableData ?? []}
                                    columns={columns}
                                    columnVisibility={columnVisibility}
                                    // deleteUrl={serverUrl + '/tim/pendataan/delete'}
                                    // detailUrl={'/detail'}
                                    state={{ isLoading: loading }}
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </SoftBox>
        </>
    )
}

export default Detail