import { ArrowBack, Save } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Autocomplete, Box, Card, CardContent, CardHeader, CircularProgress, Divider, Grid, IconButton, List, ListItem, ListItemText, ListSubheader, TextField, Tooltip, Typography } from '@mui/material'
import axios from 'axios'
import Headerx from 'components/Header/Headerx'
import SoftBox from 'components/SoftBox'
import SoftInput from 'components/SoftInput'
import SoftTypography from 'components/SoftTypography'
import { CustomDescrypt } from 'global/func'
import { serverUrl } from 'global/variable'
import React, { useEffect, useState } from 'react'
import { decodeToken } from 'react-jwt'
import { useNavigate } from 'react-router-dom'
import userStore from 'store/userStore'
import Swal from 'sweetalert2'

const Tambah = () => {
    const navigate = useNavigate();
    const encryptUser = localStorage.getItem('AuthPersist');
    const encryptUserJson = JSON.parse(encryptUser);
    const decryptedUser = encryptUserJson.state.user === null ? "" : JSON.parse(CustomDescrypt(encryptUserJson.state.user));
    const logOut = userStore((state) => state.logOut)
    const decodedUser = decryptedUser === '' ? '' : decodeToken(decryptedUser);

    const [loading, setLoading] = useState(false)
    const [listDpt, setListDpts] = useState([])
    const [dpt, setDpt] = useState({})

    const [formData, setFormData] = useState({
        username: '',
        password: '',
        nama: '',
        nik: '',
        no_hp: '',
        id_kec: decodedUser.id_kec,
        nama_kec: decodedUser.nama_kec,
        id_kel: decodedUser.id_kel,
        nama_kel: decodedUser.nama_kel
    })

    const submit = (event) => {
        event.preventDefault();
        setLoading(true)
        // setFormData({ ...formData, tgl: tanggal.format('YYYY-MM-DD') })
        axios.post(
            serverUrl + '/korkel/vote-getter/insert',
            formData,
            {
                headers: {
                    'Authorization': `Bearer ${decryptedUser}`,
                    'content-type': 'multipart/form-data'
                }
            })
            .then((response) => {
                console.log(response);
                Swal.fire({
                    icon: 'success',
                    title: 'Berhasil!',
                    text: response.data
                })
                navigate(-1)
                setLoading(false)
            })
            .catch((error) => {
                console.log(error);
                if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                    logOut();
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.response.data.messages.error
                    })
                }
                setLoading(false)
            })
    }

    const reqDpt = (id_kel) => {
        // const decodedUser = decodeToken(decryptedUser);
        // console.log(decodedUser.id_kel);
        // setLoading(true)
        axios.post(
            serverUrl + '/dpt',
            {
                // username: decodedUser.username,
                id_kel: decodedUser.id_kel,
                tim: ''
                // dtd_username: '',
            },
            {
                headers: {
                    'Authorization': `Bearer ${decryptedUser}`,
                    'content-type': 'multipart/form-data'
                }
            })
            .then((response) => {
                console.log(response);
                setListDpts(response.data)
                // setLoading(false)
            })
            .catch((error) => {
                if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                    logOut();
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.response.status + ' ' + error.response.statusText
                    })
                }
                // setLoading(false)
            })
    }

    useEffect(() => {
        reqDpt()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        console.log(formData);
    }, [formData])

    return (
        <>
            <Headerx title='Tambah Data' />
            <SoftBox mb={3}>
                <Grid container spacing={3} justifyContent='center' py={5}>
                    <Grid item xs={12} sm={12} md={9}>
                        <Card >
                            <CardHeader
                                title={
                                    <SoftTypography variant='h6'>Kembali</SoftTypography>
                                }
                                avatar={
                                    <Tooltip title="Kembali">
                                        <IconButton variant="outlined" onClick={() => navigate(-1)}><ArrowBack /></IconButton>
                                    </Tooltip>
                                }
                            />
                            {
                                loading ?
                                    (
                                        <Grid container spacing={3} py={5} justifyContent="center">
                                            <Grid item>
                                                <Box sx={{ display: 'flex' }}>
                                                    <CircularProgress />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    )
                                    :
                                    (
                                        <CardContent component='form' onSubmit={submit}>
                                            <Grid container justifyContent='center' direction='column'>
                                                <Grid item xs={12} sm={12} lg={6}>
                                                    <SoftBox mb={2} justifyContent="center">
                                                        <SoftBox mb={1} ml={0.5}>
                                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                Kecamatan
                                                            </SoftTypography>
                                                        </SoftBox>
                                                        <SoftInput
                                                            type="text"
                                                            defaultValue={formData.nama_kec}
                                                            // onChange={(event) => setFormData({ ...formData, username: event.target.value })}
                                                            autoComplete='off'
                                                            disabled
                                                        />
                                                    </SoftBox>
                                                </Grid>
                                                <Grid item xs={12} sm={12} lg={6}>
                                                    <SoftBox mb={2} justifyContent="center">
                                                        <SoftBox mb={1} ml={0.5}>
                                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                Kelurahan
                                                            </SoftTypography>
                                                        </SoftBox>
                                                        <SoftInput
                                                            type="text"
                                                            defaultValue={formData.nama_kel}
                                                            // onChange={(event) => setFormData({ ...formData, username: event.target.value })}
                                                            autoComplete='off'
                                                            disabled
                                                        />
                                                    </SoftBox>
                                                </Grid>
                                                <Grid item xs={12} sm={12} lg={6}>
                                                    <SoftBox mb={2} justifyContent="center">
                                                        <SoftBox mb={1} ml={0.5}>
                                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                Username
                                                            </SoftTypography>
                                                        </SoftBox>
                                                        <SoftInput
                                                            type="text"
                                                            defaultValue={formData.username}
                                                            onChange={(event) => setFormData({ ...formData, username: event.target.value })}
                                                            autoComplete='off'
                                                            required
                                                        />
                                                    </SoftBox>
                                                </Grid>
                                                <Grid item xs={12} sm={12} lg={6}>
                                                    <SoftBox mb={2} justifyContent="center">
                                                        <SoftBox mb={1} ml={0.5}>
                                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                Password
                                                            </SoftTypography>
                                                        </SoftBox>
                                                        <SoftInput
                                                            type="text"
                                                            defaultValue={formData.password}
                                                            onChange={(event) => setFormData({ ...formData, password: event.target.value })}
                                                            autoComplete='off'
                                                            required
                                                        />
                                                    </SoftBox>
                                                </Grid>
                                                <Grid item xs={12} sm={12} lg={6}>
                                                    <SoftBox mb={2} justifyContent="center">
                                                        <SoftBox mb={1} ml={0.5}>
                                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                Nama
                                                            </SoftTypography>
                                                        </SoftBox>
                                                        <Autocomplete
                                                            // disablePortal
                                                            freeSolo
                                                            id="combo-box-demo"
                                                            options={listDpt}
                                                            fullWidth
                                                            getOptionLabel={(option) => option.nik + ' ' + option.nama}
                                                            // getOptionLabel={(option) => option.nama}
                                                            onChange={(event, newValue) => {
                                                                newValue !== null && setFormData({ ...formData, nik: newValue.nik, nama: newValue.nama })
                                                                newValue !== null && setDpt(newValue)
                                                            }}
                                                            onInputChange={(event, newInputValue) => {
                                                                newInputValue === "" && setFormData({ ...formData, nik: '', nama: '' })
                                                                newInputValue === "" && setDpt({})
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                />
                                                            )}
                                                        // renderOption={(props, option, index) => (
                                                        //     <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                        //         {index + '. ' +option.nik.slice(0, 4)+'****'+option.nik.slice(8,17)+' '+option.nama}
                                                        //     </Box>
                                                        // )}
                                                        />
                                                    </SoftBox>
                                                </Grid>
                                                <Grid item xs={12} sm={12} lg={6}>
                                                    <SoftBox mb={2} justifyContent="center">
                                                        <SoftBox mb={1} ml={0.5}>
                                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                No. HP
                                                            </SoftTypography>
                                                        </SoftBox>
                                                        <SoftInput
                                                            type="text"
                                                            defaultValue={formData.no_hp}
                                                            onChange={(event) => setFormData({ ...formData, no_hp: event.target.value })}
                                                            autoComplete='off'
                                                            required
                                                            inputProps={{ maxLength: 12 }}
                                                        />
                                                    </SoftBox>
                                                </Grid>
                                                {
                                                    formData.nik !== '' && (
                                                        <Grid item xs={12} sm={12} lg={6} >
                                                            <List sx={{ bgcolor: 'background.paper' }} subheader={
                                                                <ListSubheader component="div" id="nested-list-subheader">
                                                                    Data Vote Getter
                                                                </ListSubheader>
                                                            }>
                                                                <ListItem>
                                                                    <ListItemText primary={
                                                                        <Typography
                                                                            variant="body2"
                                                                            color="text.primary"
                                                                        >
                                                                            NIK
                                                                        </Typography>
                                                                    }
                                                                        secondary={
                                                                            <Typography
                                                                                variant="body2"
                                                                                color="text.primary"
                                                                            >
                                                                                {dpt.nik ? dpt.nik : '-'}
                                                                            </Typography>
                                                                        } />
                                                                </ListItem>
                                                                <Divider />
                                                                <ListItem>
                                                                    <ListItemText primary={
                                                                        <Typography
                                                                            variant="body2"
                                                                            color="text.primary"
                                                                        >
                                                                            No. KK
                                                                        </Typography>
                                                                    }
                                                                        secondary={
                                                                            <Typography
                                                                                variant="body2"
                                                                                color="text.primary"
                                                                            >
                                                                                {dpt.no_kk ? dpt.no_kk : '-'}
                                                                            </Typography>
                                                                        } />
                                                                </ListItem>
                                                                <Divider />
                                                                <ListItem>
                                                                    <ListItemText primary={
                                                                        <Typography
                                                                            variant="body2"
                                                                            color="text.primary"
                                                                        >
                                                                            Nama
                                                                        </Typography>
                                                                    }
                                                                        secondary={
                                                                            <Typography
                                                                                variant="body2"
                                                                                color="text.primary"
                                                                            >
                                                                                {dpt.nama ? dpt.nama : '-'}
                                                                            </Typography>
                                                                        } />
                                                                </ListItem>
                                                                <Divider />
                                                                <ListItem>
                                                                    <ListItemText
                                                                        primary={
                                                                            <Typography
                                                                                variant="body2"
                                                                                color="text.primary"
                                                                            >
                                                                                Tempat/Tgl Lahir
                                                                            </Typography>
                                                                        }
                                                                        secondary={
                                                                            <Typography
                                                                                variant="body2"
                                                                                color="text.primary"
                                                                            >
                                                                                {dpt.tempat_lahir ? dpt.tempat_lahir + ', ' + dpt.tgl_lahir : '-'}
                                                                            </Typography>
                                                                        }
                                                                    />
                                                                </ListItem>
                                                                <Divider />
                                                                <ListItem>
                                                                    <ListItemText
                                                                        primary={
                                                                            <Typography
                                                                                variant="body2"
                                                                                color="text.primary"
                                                                            >
                                                                                Status Kawin
                                                                            </Typography>
                                                                        }
                                                                        secondary={
                                                                            <Typography
                                                                                variant="body2"
                                                                                color="text.primary"
                                                                            >
                                                                                {
                                                                                    dpt.stts_kawin
                                                                                }
                                                                            </Typography>
                                                                        }
                                                                    />
                                                                </ListItem>
                                                                <Divider />
                                                                <ListItem>
                                                                    <ListItemText
                                                                        primary={
                                                                            <Typography
                                                                                variant="body2"
                                                                                color="text.primary"
                                                                            >
                                                                                Jenis Kelamin
                                                                            </Typography>
                                                                        }
                                                                        secondary={
                                                                            <Typography
                                                                                variant="body2"
                                                                                color="text.primary"
                                                                            >
                                                                                {dpt.jenkel === 'L' ? 'Laki-laki' : 'Perempuan'}
                                                                            </Typography>
                                                                        }
                                                                    />
                                                                </ListItem>
                                                                <Divider />
                                                                <ListItem>
                                                                    <ListItemText
                                                                        primary={
                                                                            <Typography
                                                                                variant="body2"
                                                                                color="text.primary"
                                                                            >
                                                                                Alamat
                                                                            </Typography>
                                                                        }
                                                                        secondary={
                                                                            <Typography
                                                                                variant="body2"
                                                                                color="text.primary"
                                                                            >
                                                                                {dpt.alamat ? `${dpt.alamat} RT. ${dpt.rt} RW. ${dpt.rw}, Kel. ${dpt.nama_kel}, Kec. ${dpt.nama_kec}, Kota. ${dpt.nama_kab}` : '-'}
                                                                            </Typography>
                                                                        } />
                                                                </ListItem>
                                                                <Divider />
                                                                <ListItem>
                                                                    <ListItemText
                                                                        primary={
                                                                            <Typography
                                                                                variant="body2"
                                                                                color="text.primary"
                                                                            >
                                                                                RT / RW
                                                                            </Typography>
                                                                        }
                                                                        secondary={
                                                                            <Typography
                                                                                variant="body2"
                                                                                color="text.primary"
                                                                            >
                                                                                RT. {dpt.rt}, RW. {dpt.rw}
                                                                            </Typography>
                                                                        } />
                                                                </ListItem>
                                                                <Divider />
                                                                <ListItem>
                                                                    <ListItemText primary={
                                                                        <Typography
                                                                            variant="body2"
                                                                            color="text.primary"
                                                                        >
                                                                            TPS
                                                                        </Typography>
                                                                    }
                                                                        secondary={
                                                                            <Typography
                                                                                variant="body2"
                                                                                color="text.primary"
                                                                            >
                                                                                {dpt.nama_tps}
                                                                            </Typography>
                                                                        } />
                                                                </ListItem>
                                                                <Divider />
                                                            </List>
                                                        </Grid>
                                                    )
                                                }
                                                <Grid item xs={12} sm={12} lg={6}>
                                                    <Grid container justifyContent='flex-end'>
                                                        <Grid item>
                                                            <SoftBox mb={1} ml={0.5}>
                                                                <LoadingButton
                                                                    loading={loading}
                                                                    loadingPosition="start"
                                                                    startIcon={<Save />}
                                                                    variant="contained"
                                                                    type='submit'
                                                                >
                                                                    Save
                                                                </LoadingButton>
                                                            </SoftBox>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    )
                            }

                        </Card>
                    </Grid>
                </Grid>
            </SoftBox>
        </>
    )
}

export default Tambah