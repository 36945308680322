import { Diversity3, Flag, Home, Menu, People } from "@mui/icons-material"

const KorcamRoutes = [
    {
        type: "route",
        name: "Dashboard",
        key: [''],
        route: "/korcam",
        icon: <Home size="12px" color="warning" />,
        noCollapse: true,
    },
    {
        type: "route",
        name: "Pendataan DTD",
        key: ['dtd'],
        route: "/korcam/dtd",
        icon: <Menu size="12px" color="info" />,
        noCollapse: true,
    },
    {
        type: "route",
        name: "Pertemuan Terbatas",
        key: ['pt'],
        route: "/korcam/pt",
        icon: <Diversity3 size="12px" color="secondary" />,
        noCollapse: true,
    },
    {
        type: "route",
        name: "APK",
        key: ['apk'],
        route: "/korcam/apk",
        icon: <Flag size="12px" color="success" />,
        noCollapse: true,
    },
    {
        type: "collapse",
        name: "Tim",
        key: ['korkel', 'vote-getter'],
        icon: <People size="12px" color="error" />,
        collapse: [
            {
                name: "Relawan Brigade",
                key: ["/korcam/korkel", "/korcam/korkel/tambah"],
                route: "/korcam/korkel"
            },
            {
                name: "Vote Getter",
                key: ["/korcam/vote-getter", "/korcam/vote-getter/detail"],
                route: "/korcam/vote-getter"
            }
        ],
    }
]

export default KorcamRoutes