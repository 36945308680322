import { AddCircleOutline } from '@mui/icons-material';
import { Button, Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import axios from 'axios';
import MiniStatisticsCard from 'components/Cards/StatisticsCards/MiniStatisticsCard';
import Headerx from 'components/Header/Headerx'
import SoftBox from 'components/SoftBox';
import { CustomDescrypt } from 'global/func';
import { serverUrl } from 'global/variable';
import MyTable from 'myComponent/Table/MyTable';
import React, { useEffect, useState } from 'react'
import { Link, Route, Routes } from 'react-router-dom';
import userStore from 'store/userStore';
import Swal from 'sweetalert2';
import Tambah from './Tambah';
import Detail from './Detail';

const VoteGetter = () => {
    return (
        <Routes>
            <Route exact path='' element={<Data />} />
            <Route exact path='detail' element={<Detail />} />
            <Route exact path='tambah' element={<Tambah />} />
            {/* <Route exact path='edit' element={<Edit />} /> */}
        </Routes>
    )
}

const Data = () => {
    const encryptUser = localStorage.getItem('AuthPersist');
    const encryptUserJson = JSON.parse(encryptUser);
    const decryptedUser = encryptUserJson.state.user === null ? "" : JSON.parse(CustomDescrypt(encryptUserJson.state.user));
    const logOut = userStore((state) => state.logOut)

    const [loading, setLoading] = useState(true)
    const [tableData, setTableData] = useState([]);
    const [deleting, setDeleting] = useState(false);

    useEffect(() => {
        requestData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleting])

    const requestData = () => {
        setLoading(true)
        axios.get(
            serverUrl + '/korkel/vote-getter',
            {
                headers: {
                    'Authorization': `Bearer ${decryptedUser}`,
                    'content-type': 'multipart/form-data'
                }
            })
            .then((response) => {
                // console.log(response);
                setTableData(response.data)
                setLoading(false)
            })
            .catch((error) => {
                console.log(error);
                if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                    logOut();
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.response.status + ' ' + error.response.statusText
                    })
                }
                setLoading(false)
            })
    }

    const columns = [
        //fields = { ['no_kk', 'nik_kepala', 'nama_kepala', 'pekerjaan', 'jml_anggota', 'no_hp', 'created_at', 'nama_petugas']}
        //headers = { ['No KK', 'NIK KK', 'Nama KK', 'Pekerjaan', 'Jml Anggota', 'No. HP', 'Jam Input', 'Tim']}
        {
            accessorKey: 'username',
            header: 'Username',
            // filterVariant: 'text',
            // filterVariant: 'select',
            // filterSelectOptions: listKec
        },
        {
            accessorKey: 'password',
            header: 'Password'
        },
        {
            accessorKey: 'nama',
            header: 'Nama'
        },
        {
            accessorKey: 'no_hp',
            header: 'No. HP'
        },
        {
            accessorKey: 'anggota',
            header: 'Anggota'
        },
    ]

    const columnVisibility = {
        password: false,
    }

    return (
        <>
            <Headerx title='Vote Getter' />
            <SoftBox my={3}>
                <Grid container spacing={3} mb={3} justifyContent='center'>
                    <Grid item xs={12} lg={12}>
                        <MiniStatisticsCard
                            title={{ text: "Total Data" }}
                            count={tableData.length}
                            // percentage={{ color: "success", text: "+55%" }}
                            icon={{ color: "info", component: "paid" }}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={3} justifyContent='center'>
                    <Grid item xs={12} lg={12}>
                        <Card >
                            <CardHeader
                                title={
                                    <Typography>
                                        Vote Getter
                                    </Typography>
                                }
                                action={
                                    <Button
                                        variant="contained"
                                        endIcon={<AddCircleOutline color='white' />}
                                        // onClick={() => handleOpenDialog2()}
                                        to="/korkel/vote-getter/tambah"
                                        component={Link}
                                        // disabled={tableData.length >= 10}
                                    >
                                        Tambah
                                    </Button>
                                }
                            />

                            <CardContent>
                                <MyTable
                                    data={tableData ?? []}
                                    // headers={['No KK', 'NIK KK', 'Nama KK', 'Pekerjaan', 'Jml Anggota', 'No. HP', 'Jam Input', 'Tim']}
                                    // fields={['no_kk', 'nik_kepala', 'nama_kepala', 'pekerjaan', 'jml_anggota', 'no_hp', 'created_at', 'nama_petugas']}
                                    columns={columns}
                                    columnVisibility={columnVisibility}
                                    deleting={deleting}
                                    setDeleting={setDeleting}
                                    deleteUrl={serverUrl + '/korkel/vote-getter/delete'}
                                    deleteField='username'
                                    detailUrl={'/detail'}
                                    state={{ isLoading: loading }}
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </SoftBox>
        </>
    )
}

export default VoteGetter