import { Home } from "@mui/icons-material";

const voteGetterRoutes = [
    // {
    //     type: "collapse",
    //     name: "Dashboard",
    //     key: [''],
    //     route: "/vote-getter",
    //     icon: <Home size="12px" />,
    //     noCollapse: true,
    // },
    {
        type: "route",
        name: "Pendataan DTD",
        key: ['dtd'],
        route: "/vote-getter/dtd",
        icon: <Home size="12px" color="warning" />,
        noCollapse: true,
    },
    // {
    //     type: "collapse",
    //     name: "User",
    //     key: ['tim', 'admin'],
    //     icon: <Person size="12px" />,
    //     collapse: [
    //         {
    //             name: "Tim",
    //             key: ["/admin/tim", "/admin/tim/tambah", "/admin/tim/edit", "/admin/tim/detail", "/admin/tim/detail/detail-keluarga"],
    //             route: "/admin/tim"
    //         },
    //         {
    //             name: "Admin",
    //             key: ["/admin/admin", "/admin/admin/tambah", "/admin/admin/edit"],
    //             route: "/admin/admin"
    //         }
    //     ],
    // },
    // {
    //     type: "collapse",
    //     name: "Kecamatan",
    //     key: ['kecamatan'],
    //     route: "/admin/kecamatan",
    //     icon: <LocationOn size="12px" />,
    //     noCollapse: true,
    // },
];

export default voteGetterRoutes;
