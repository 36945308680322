import { ArrowBack, Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Autocomplete, Box, Card, CardContent, CardHeader, CircularProgress, FormControl, FormControlLabel, FormLabel, Grid, IconButton, MenuItem, Radio, RadioGroup, Select, TextField, Tooltip } from '@mui/material';
import axios from 'axios';
import Headerx from 'components/Header/Headerx';
import SoftBox from 'components/SoftBox';
import SoftInput from 'components/SoftInput';
import SoftTypography from 'components/SoftTypography';
import { CustomDescrypt } from 'global/func';
import { serverUrl } from 'global/variable';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import userStore from 'store/userStore';
import Swal from 'sweetalert2';

const TambahPj = () => {
    const navigate = useNavigate();
    const encryptUser = localStorage.getItem('AuthPersist');
    const encryptUserJson = JSON.parse(encryptUser);
    const decryptedUser = encryptUserJson.state.user === null ? "" : JSON.parse(CustomDescrypt(encryptUserJson.state.user));
    const logOut = userStore((state) => state.logOut)


    const [loading, setLoading] = useState(false)
    const [listDpt, setListDpts] = useState([])
    // const [dpt, setDpt] = useState({})

    const [listVg, setListVg] = useState([])
    // const [isDptKdi, setIsDptKdi] = useState('')
    // const [vg, setVg] = useState({})
    const [desas, setDesas] = useState([]);
    const [kecs, setKecs] = useState([]);
    const [desa, setDesa] = useState('');
    const [kec, setKec] = useState('');

    const [formData, setFormData] = useState({
        nik: '',
        nama: '',
        no_hp: '',
        vote_getter: '',
        dpt_kdi: ''
    })

    useEffect(() => {
        console.log(formData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData])

    const submit = (event) => {
        event.preventDefault();
        setLoading(true)
        // setFormData({...formData, agg})
        axios.post(
            serverUrl + '/thi_tim/suara_tambahan/insert-pj',
            formData,
            {
                headers: {
                    'Authorization': `Bearer ${decryptedUser}`,
                    'content-type': 'multipart/form-data'
                }
            })
            .then((response) => {
                console.log(response);
                Swal.fire({
                    icon: 'success',
                    title: 'Berhasil!',
                    text: response.data.msg
                })
                navigate(-1)
                setLoading(false)
            })
            .catch((error) => {
                console.log(error);
                if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                    logOut();
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.response.data.messages.msg
                    })
                }
                setLoading(false)
            })
    }

    const handleIsVGChange = (event) => {
        if (event.target.value === 'Ya') {
            console.log('reqVG');
            reqVg()
            // setFormData({ ...formData, dpt_kdi: "Ya" })
            // setFormData({ ...formData, dpt_kdi: 'Ya' })
        }
        setFormData({ ...formData, vote_getter: event.target.value })
    }

    const handleDptKdiChange = (event) => {
        setFormData({ ...formData, dpt_kdi: event.target.value })
    }

    const reqVg = () => {
        // setLoading(true)
        axios.get(
            serverUrl + '/get-vote-getter',
            {
                headers: {
                    'Authorization': `Bearer ${decryptedUser}`,
                    'content-type': 'multipart/form-data'
                }
            })
            .then((response) => {
                console.log(response);
                setListVg(response.data)
                setLoading(false)
            })
            .catch((error) => {
                console.log(error);
                if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                    logOut();
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.response.status + ' ' + error.response.statusText
                    })
                }
            })
    }

    const handleChangeDesa = (event) => {
        if (event.target.value === '') {
            setDesa('')
        }
        else {
            setDesa(event.target.value)
            reqDpt(event.target.value)
        }
    };

    useEffect(() => {
        getKec()
    }, [])

    const getKec = async () => {
        const kec = await axios.get(serverUrl + '/kecamatan');
        setKecs(kec.data)
    }

    const handleChangeKec = (event) => {
        if (event.target.value === '') {
            setKec('')
            setDesa('')
        }
        else {
            setKec(event.target.value)
            getDesa(event.target.value)
        }
    };

    const getDesa = async (params) => {
        const data = await axios.get(serverUrl + '/desa/' + params);
        setDesas(data.data)
        // console.log(data);
    }

    const reqDpt = (idKel) => {
        // const decodedUser = decodeToken(decryptedUser);
        // console.log(decodedUser.id_kel);
        // setLoading(true)
        axios.post(
            serverUrl + '/dpt',
            {
                id_kel: idKel
            },
            {
                headers: {
                    'Authorization': `Bearer ${decryptedUser}`,
                    'content-type': 'multipart/form-data'
                }
            })
            .then((response) => {
                console.log(response);
                setListDpts(response.data)
                setLoading(false)
            })
            .catch((error) => {
                console.log(error);
                if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                    logOut();
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.response.status + ' ' + error.response.statusText
                    })
                }
                // setLoading(false)
            })
    }

    return (
        <>
            <Headerx title='Tambah Data' />
            <SoftBox mb={3}>
                <Grid container spacing={3} justifyContent='center' py={5}>
                    <Grid item xs={12} sm={12} md={9}>
                        <Card >
                            <CardHeader
                                title={
                                    <SoftTypography variant='h6'>Kembali</SoftTypography>
                                }
                                avatar={
                                    <Tooltip title="Kembali">
                                        <IconButton variant="outlined" onClick={() => navigate(-1)}><ArrowBack /></IconButton>
                                    </Tooltip>
                                }
                            />
                            {
                                loading ?
                                    (
                                        <Grid container spacing={3} py={5} justifyContent="center">
                                            <Grid item>
                                                <Box sx={{ display: 'flex' }}>
                                                    <CircularProgress />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    )
                                    :
                                    (
                                        <CardContent component='form' onSubmit={submit}>
                                            <Grid container spacing={3} justifyContent="center">
                                                <Grid item xs={12} sm={12} lg={6}>
                                                    <SoftBox mb={1} ml={0.5}>
                                                        <FormControl>
                                                            <FormLabel id="demo-row-radio-buttons-group-label">
                                                                <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                    Vote Getter?
                                                                </SoftTypography>
                                                            </FormLabel>
                                                            <RadioGroup
                                                                row
                                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                                name="row-radio-buttons-group"
                                                                // defaultValue={formData.vote_getter}
                                                                onChange={handleIsVGChange}
                                                                value={formData.vote_getter}
                                                            >
                                                                <FormControlLabel value="Ya" control={<Radio />} label="Ya" />
                                                                <FormControlLabel value="Tidak" control={<Radio />} label="Tidak" />
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </SoftBox>
                                                </Grid>
                                            </Grid>
                                            {
                                                formData.vote_getter === 'Ya' ?
                                                    (
                                                        <Grid container spacing={3} justifyContent="center" mb={2}>
                                                            <Grid item xs={12} sm={12} lg={6} >
                                                                <SoftBox mb={1} ml={0.5}>
                                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                        Pilih Vote Getter
                                                                    </SoftTypography>
                                                                </SoftBox>
                                                                <Autocomplete
                                                                    disablePortal
                                                                    id="combo-box-demo"
                                                                    options={listVg}
                                                                    fullWidth
                                                                    getOptionLabel={(option) => option.nik + ' ' + option.nama}
                                                                    onChange={(event, newValue) => {
                                                                        newValue !== null && setFormData({ ...formData, nik: newValue.nik, nama: newValue.nama, no_hp: newValue.no_hp })
                                                                    }}
                                                                    onInputChange={(event, newInputValue) => {
                                                                        newInputValue === "" && setFormData({ ...formData, nik: '' })
                                                                    }}
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            {...params}
                                                                        />
                                                                    )}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                    :
                                                    formData.vote_getter === 'Tidak' &&
                                                    (
                                                        <>
                                                            <Grid container spacing={3} justifyContent="center">
                                                                <Grid item xs={12} sm={12} lg={6}>
                                                                    <SoftBox mb={1} ml={0.5}>
                                                                        <FormControl>
                                                                            <FormLabel id="demo-row-radio-buttons-group-label">
                                                                                <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                                    DPT Kendari??
                                                                                </SoftTypography>
                                                                            </FormLabel>
                                                                            <RadioGroup
                                                                                row
                                                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                                                name="row-radio-buttons-group"
                                                                                onChange={handleDptKdiChange}
                                                                                value={formData.dpt_kdi}
                                                                            >
                                                                                <FormControlLabel value="Ya" control={<Radio color='primary' />} label="Ya" />
                                                                                <FormControlLabel value="Tidak" control={<Radio color='primary' />} label="Tidak" />
                                                                            </RadioGroup>
                                                                        </FormControl>
                                                                    </SoftBox>
                                                                </Grid>
                                                            </Grid>
                                                            {
                                                                formData.dpt_kdi === 'Ya' ?
                                                                    (
                                                                        <>
                                                                            <Grid container spacing={3} justifyContent="center">
                                                                                <Grid item xs={12} sm={12} lg={6}>
                                                                                    <SoftBox mb={1} ml={0.5}>
                                                                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                                            Pilih Kecamatan
                                                                                        </SoftTypography>
                                                                                    </SoftBox>
                                                                                    <Select
                                                                                        labelId="demo-simple-select-label"
                                                                                        id="demo-simple-select"
                                                                                        defaultValue=''
                                                                                        value={kec}
                                                                                        label="Kecamatan"
                                                                                        onChange={handleChangeKec}
                                                                                        required
                                                                                    >
                                                                                        <MenuItem value=''><em>Pilih Desa</em></MenuItem>
                                                                                        {
                                                                                            kecs.map((kec) => (
                                                                                                <MenuItem key={kec.id_kec} value={kec.id_kec}>{kec.nama_kec}</MenuItem>
                                                                                            ))
                                                                                        }
                                                                                    </Select>
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid container spacing={3} justifyContent="center">
                                                                                <Grid item xs={12} sm={12} lg={6}>
                                                                                    <SoftBox mb={1} ml={0.5}>
                                                                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                                            Pilih Kelurahan
                                                                                        </SoftTypography>
                                                                                    </SoftBox>
                                                                                    <Select
                                                                                        labelId="demo-simple-select-label"
                                                                                        id="demo-simple-select"
                                                                                        defaultValue=''
                                                                                        value={desa}
                                                                                        label="Desa"
                                                                                        onChange={handleChangeDesa}
                                                                                        required
                                                                                    >
                                                                                        <MenuItem value=''><em>Pilih Desa</em></MenuItem>
                                                                                        {
                                                                                            desas.map((des) => (
                                                                                                <MenuItem key={des.id_desa} value={des.id_desa}>{des.nama_desa}</MenuItem>
                                                                                            ))
                                                                                        }
                                                                                    </Select>
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid container spacing={3} justifyContent="center" mb={1}>
                                                                                <Grid item xs={12} sm={12} lg={6}>
                                                                                    <SoftBox mb={1} ml={0.5}>
                                                                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                                            NIK
                                                                                        </SoftTypography>
                                                                                    </SoftBox>
                                                                                    <Autocomplete
                                                                                        disablePortal
                                                                                        id="combo-box-demo"
                                                                                        options={listDpt}
                                                                                        fullWidth
                                                                                        getOptionLabel={(option) => option.nik + ' ' + option.nama}
                                                                                        onChange={(event, newValue) => {
                                                                                            newValue !== null && setFormData({ ...formData, nik: newValue.nik, nama: newValue.nama })
                                                                                        }}
                                                                                        onInputChange={(event, newInputValue) => {
                                                                                            newInputValue === "" && setFormData({ ...formData, nik: '', nama: '' })
                                                                                        }}
                                                                                        renderInput={(params) => (
                                                                                            <TextField
                                                                                                {...params}
                                                                                            />
                                                                                        )}
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                        </>
                                                                    )
                                                                    :
                                                                    formData.dpt_kdi === 'Tidak' &&
                                                                    (
                                                                        <Grid container spacing={3} justifyContent="center">
                                                                            <Grid item xs={12} sm={12} lg={6}>
                                                                                <SoftBox mb={2} justifyContent="center">
                                                                                    <SoftBox mb={1} ml={0.5}>
                                                                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                                            Nama
                                                                                        </SoftTypography>
                                                                                    </SoftBox>
                                                                                    <SoftInput
                                                                                        type="text"
                                                                                        defaultValue={formData.nama}
                                                                                        onChange={(event) => setFormData({ ...formData, nama: event.target.value })}
                                                                                        autoComplete='off'
                                                                                        required
                                                                                    />
                                                                                </SoftBox>
                                                                            </Grid>
                                                                        </Grid>
                                                                    )
                                                            }
                                                        </>
                                                    )
                                            }
                                            <Grid container spacing={3} justifyContent="center" mb={1}>
                                                <Grid item xs={12} sm={12} lg={6}>
                                                    <SoftBox mb={2} justifyContent="center">
                                                        <SoftBox mb={1} ml={0.5}>
                                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                No. HP
                                                            </SoftTypography>
                                                        </SoftBox>
                                                        <SoftInput
                                                            type="text"
                                                            value={formData.no_hp}
                                                            onChange={(event) => setFormData({ ...formData, no_hp: event.target.value })}
                                                            autoComplete='off'
                                                            required
                                                            inputProps={{ maxLength: 12 }}
                                                        />
                                                    </SoftBox>
                                                </Grid>
                                            </Grid>
                                            <Grid container justifyContent='center'>
                                                <Grid item xs={12} sm={12} lg={6}>
                                                    <Grid container justifyContent='flex-end'>
                                                        <Grid item>
                                                            <SoftBox mb={1} ml={0.5}>
                                                                <LoadingButton
                                                                    loading={loading}
                                                                    loadingPosition="start"
                                                                    startIcon={<Save />}
                                                                    variant="contained"
                                                                    type='submit'
                                                                >
                                                                    Save
                                                                </LoadingButton>
                                                            </SoftBox>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    )
                            }

                        </Card>
                    </Grid>
                </Grid>
            </SoftBox>
        </>
    )
}

export default TambahPj