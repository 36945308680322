import { ArrowBack, Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Card, CardContent, CardHeader, CircularProgress, Grid, IconButton, MenuItem, Select, Tooltip } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import Headerx from 'components/Header/Headerx'
import SoftBox from 'components/SoftBox';
import SoftInput from 'components/SoftInput';
import SoftTypography from 'components/SoftTypography';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { CustomDescrypt } from 'global/func';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import userStore from 'store/userStore';
import axios from 'axios';
import { serverUrl } from 'global/variable';
import Swal from 'sweetalert2';
import SelectKec from 'myComponent/SelectKec';
import SelectDesa from 'myComponent/SelectDesa';

const Tambah = () => {
    const navigate = useNavigate();
    const encryptUser = localStorage.getItem('AuthPersist');
    const encryptUserJson = JSON.parse(encryptUser);
    const decryptedUser = encryptUserJson.state.user === null ? "" : JSON.parse(CustomDescrypt(encryptUserJson.state.user));
    const logOut = userStore((state) => state.logOut)

    const [tanggal, setTanggal] = useState(dayjs(new Date()));

    const [loading, setLoading] = useState(false)
    const [jenisBansos, setJenisBansos] = useState([])

    const [formData, setFormData] = useState({
        jenis: '',
        judul: '',
        id_kec: '',
        nama_kec: '',
        id_kel: '',
        nama_kel: '',
        tgl: ''
    })

    useEffect(() => {
        reqJenisBansos()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setFormData({...formData, tgl: tanggal.format('YYYY-MM-DD')})
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tanggal])

    useEffect(() => {
        console.log(formData);
    }, [formData])

    const reqJenisBansos = () => {
        setLoading(true)
        axios.get(
            serverUrl + '/jenis_bansos')
            .then((response) => {
                console.log(response);
                setJenisBansos(response.data)
                setLoading(false)
            })
            .catch((error) => {
                if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                    logOut();
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.response.status + ' ' + error.response.statusText
                    })
                }
                setLoading(false)
            })
    }

    const submit = (event) => {
        event.preventDefault();
        setLoading(true)
        // setFormData({ ...formData, tgl: tanggal.format('YYYY-MM-DD') })
        axios.post(
            serverUrl + '/thi_tim/bansos/insert',
            formData,
            {
                headers: {
                    'Authorization': `Bearer ${decryptedUser}`,
                    'content-type': 'multipart/form-data'
                }
            })
            .then((response) => {
                console.log(response);
                Swal.fire({
                    icon: 'success',
                    title: 'Berhasil!',
                    text: response.data.msg
                })
                navigate(-1)
                setLoading(false)
            })
            .catch((error) => {
                console.log(error);
                if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                    logOut();
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.response.data.messages.msg
                    })
                }
                setLoading(false)
            })
    }

    return (
        <>
            <Headerx title='Tambah Data' />
            <SoftBox mb={3}>
                <Grid container spacing={3} justifyContent='center' py={5}>
                    <Grid item xs={12} sm={12} md={9}>
                        <Card >
                            <CardHeader
                                title={
                                    <SoftTypography variant='h6'>Kembali</SoftTypography>
                                }
                                avatar={
                                    <Tooltip title="Kembali">
                                        <IconButton variant="outlined" onClick={() => navigate(-1)}><ArrowBack /></IconButton>
                                    </Tooltip>
                                }
                            />
                            {
                                loading ?
                                    (
                                        <Grid container spacing={3} py={5} justifyContent="center">
                                            <Grid item>
                                                <Box sx={{ display: 'flex' }}>
                                                    <CircularProgress />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    )
                                    :
                                    (
                                        <CardContent component='form' onSubmit={submit}>
                                            <Grid container justifyContent='center' direction='column'>
                                                <Grid item xs={12} sm={12} lg={6}>
                                                    <SoftBox mb={2} justifyContent="center">
                                                        <SoftBox mb={1} ml={0.5}>
                                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                Jenis Bansos
                                                            </SoftTypography>
                                                        </SoftBox>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={formData.jenis}
                                                            label="Kecamatan"
                                                            onChange={(event) => setFormData({...formData, jenis: event.target.value})}
                                                            required
                                                        >
                                                            <MenuItem value=''><em>Pilih Jenis</em></MenuItem>
                                                            {
                                                                jenisBansos.map((jenis) => (
                                                                    <MenuItem key={jenis.id} value={jenis.id}>{jenis.nama}</MenuItem>
                                                                ))
                                                            }
                                                        </Select>
                                                    </SoftBox>
                                                </Grid>
                                                <Grid item xs={12} sm={12} lg={6}>
                                                    <SoftBox mb={2} justifyContent="center">
                                                        <SoftBox mb={1} ml={0.5}>
                                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                Judul Kegiatan
                                                            </SoftTypography>
                                                        </SoftBox>
                                                        <SoftInput
                                                            type="text"
                                                            defaultValue={formData.judul}
                                                            onChange={(event) => setFormData({...formData, judul: event.target.value})}
                                                            autoComplete='off'
                                                            multiline
                                                            required
                                                            rows={4}
                                                        />
                                                    </SoftBox>
                                                </Grid>
                                                <Grid item xs={12} sm={12} lg={6}>
                                                    <SoftBox mb={2} justifyContent="center">
                                                        <SoftBox mb={1} ml={0.5}>
                                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                Tanggal Kegiatan
                                                            </SoftTypography>
                                                        </SoftBox>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <DatePicker variant='outlined' format='YYYY-MM-DD' value={tanggal} onChange={(newValue) => setTanggal(newValue)} sx={{ width: '100% !important' }} />
                                                        </LocalizationProvider>
                                                    </SoftBox>
                                                </Grid>
                                                <Grid item xs={12} sm={12} lg={6}>
                                                    <SoftBox mb={2} justifyContent="center">
                                                        <SelectKec formData={formData} setFormData={setFormData} />
                                                    </SoftBox>
                                                </Grid>
                                                <Grid item xs={12} sm={12} lg={6}>
                                                    <SoftBox mb={2} justifyContent="center">
                                                        <SelectDesa formData={formData} setFormData={setFormData} idKec={formData.id_kec} />
                                                    </SoftBox>
                                                </Grid>
                                                <Grid item xs={12} sm={12} lg={6}>
                                                    <Grid container justifyContent='flex-end'>
                                                        <Grid item>
                                                            <SoftBox mb={1} ml={0.5}>
                                                                <LoadingButton
                                                                    loading={loading}
                                                                    loadingPosition="start"
                                                                    startIcon={<Save />}
                                                                    variant="contained"
                                                                    type='submit'
                                                                >
                                                                    Save
                                                                </LoadingButton>
                                                            </SoftBox>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    )
                            }
                        </Card>
                    </Grid>
                </Grid>
            </SoftBox>
        </>
    )
}

export default Tambah