import { Home, Interests, PersonAdd } from '@mui/icons-material'
import React from 'react'

const thiTimRoutes = [
    {
        type: "route",
        name: "Dashboard",
        key: [''],
        route: "/thi_tim",
        icon: <Home size="12px" color="warning" />,
        noCollapse: true,
    },
    {
        type: "route",
        name: "Bansos",
        key: ['bansos'],
        route: "/thi_tim/bansos",
        icon: <Interests size="12px" color="info" />,
        noCollapse: true,
    },
    {
        type: "route",
        name: "Suara Tambahan",
        key: ['suara_tambahan'],
        route: "/thi_tim/suara_tambahan",
        icon: <PersonAdd size="12px" color="secondary" />,
        noCollapse: true,
    },
]

export default thiTimRoutes