import { ArrowBack, ExpandMore, Save } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Accordion, AccordionDetails, AccordionSummary, Box, Card, CardContent, CardHeader, CircularProgress, FormControl, Grid, IconButton, MenuItem, Paper, Select, Tooltip, Typography } from '@mui/material'
import axios from 'axios'
import Headerx from 'components/Header/Headerx'
import SoftBox from 'components/SoftBox'
import SoftInput from 'components/SoftInput'
import SoftTypography from 'components/SoftTypography'
import { CustomDescrypt } from 'global/func'
import { serverUrl } from 'global/variable'
import { MuiFileInput } from 'mui-file-input'
import React, { useEffect, useState } from 'react'
import { decodeToken } from 'react-jwt'
import { useNavigate } from 'react-router-dom'
import userStore from 'store/userStore'
import Swal from 'sweetalert2'

const Tambah = () => {
    const navigate = useNavigate();
    const encryptUser = localStorage.getItem('AuthPersist');
    const encryptUserJson = JSON.parse(encryptUser);
    const decryptedUser = encryptUserJson.state.user === null ? "" : JSON.parse(CustomDescrypt(encryptUserJson.state.user));
    const logOut = userStore((state) => state.logOut)
    const decodedUser = decryptedUser === '' ? '' : decodeToken(decryptedUser);

    const [loading, setLoading] = useState(false)
    const [jenisApks, setJenisApks] = useState([])
    const [formData, setFormData] = useState({
        id_kec: decodedUser.id_kec,
        nama_kec: decodedUser.nama_kec,
        id_kel: decodedUser.id_kel,
        nama_kel: decodedUser.nama_kel,
        jenis_apk: '',
        lat: '',
        longi: '',
        src: []
    })

    const loadData = (newValue) => {
        setFormData({
            ...formData,
            src: newValue
        })
        console.log(formData);
    }

    const getLocation = () => {
        if (!navigator.geolocation) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Geolocation is not supported by your browser'
            })
        } else {
            navigator.geolocation.getCurrentPosition((position) => {
                setFormData({ ...formData, lat: position.coords.latitude, longi: position.coords.longitude });
            }, () => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Unable to retrieve your location'
                })
            });
        }
    }

    useEffect(() => {
        getLocation()
        reqJenisApk()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        console.log(formData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData])

    const reqJenisApk = () => {
        setLoading(true)
        axios.get(
            serverUrl + '/jenis_apk')
            .then((response) => {
                console.log(response);
                setJenisApks(response.data)
                setLoading(false)
            })
            .catch((error) => {
                if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                    logOut();
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.response.status + ' ' + error.response.statusText
                    })
                }
                setLoading(false)
            })
    }

    const submit = (event) => {
        event.preventDefault();
        setLoading(true)
        // setFormData({...formData, agg})
        axios.post(
            serverUrl + '/korkel/apk/insert',
            formData,
            {
                headers: {
                    'Authorization': `Bearer ${decryptedUser}`,
                    'content-type': 'multipart/form-data'
                }
            })
            .then((response) => {
                console.log(response);
                Swal.fire({
                    icon: 'success',
                    title: 'Berhasil!',
                    text: response.data.msg
                })
                navigate(-1)
                setLoading(false)
            })
            .catch((error) => {
                console.log(error);
                if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                    logOut();
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.response.data.messages.msg
                    })
                }
                setLoading(false)
            })
    }

    return (
        <>
            <Headerx title='Tambah Data' />
            <SoftBox mb={3}>
                <Grid container spacing={3} justifyContent='center' py={5}>
                    <Grid item xs={12} sm={12} md={9}>
                        <Card >
                            <CardHeader
                                title={
                                    <SoftTypography variant='h6'>Kembali</SoftTypography>
                                }
                                avatar={
                                    <Tooltip title="Kembali">
                                        <IconButton variant="outlined" onClick={() => navigate(-1)}><ArrowBack /></IconButton>
                                    </Tooltip>
                                }
                            />
                            {
                                loading ?
                                    (
                                        <Grid container spacing={3} py={5} justifyContent="center">
                                            <Grid item>
                                                <Box sx={{ display: 'flex' }}>
                                                    <CircularProgress />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    )
                                    :
                                    (
                                        <CardContent component='form' onSubmit={submit}>
                                            <Grid container justifyContent='center' direction='column'>
                                                {/* <Grid item xs={12} sm={12} lg={6}>
                                                    <SoftBox mb={2} justifyContent="center">
                                                        <SoftBox mb={1} ml={0.5}>
                                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                Judul Pertemuan
                                                            </SoftTypography>
                                                        </SoftBox>
                                                        <SoftInput
                                                            type="text"
                                                            defaultValue={judul}
                                                            onChange={(event) => setJudul(event.target.value)}
                                                            autoComplete='off'
                                                            multiline
                                                            rows={4}
                                                        />
                                                    </SoftBox>
                                                </Grid>
                                                <Grid item xs={12} sm={12} lg={6}>
                                                    <SoftBox mb={2} justifyContent="center">
                                                        <SoftBox mb={1} ml={0.5}>
                                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                Tanggal Pertemuan
                                                            </SoftTypography>
                                                        </SoftBox>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <DatePicker variant='outlined' format='YYYY-MM-DD' value={tanggal} onChange={(newValue) => setTanggal(newValue)} sx={{ width: '100% !important' }} />
                                                        </LocalizationProvider>
                                                    </SoftBox>
                                                </Grid> */}
                                                <Grid item xs={12} sm={12} lg={6}>
                                                    <SoftBox mb={2} justifyContent="center">
                                                        <SoftBox mb={1} ml={0.5}>
                                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                Kecamatan
                                                            </SoftTypography>
                                                        </SoftBox>
                                                        <SoftInput
                                                            type="text"
                                                            defaultValue={formData.nama_kec}
                                                            // onChange={(event) => setFormData({ ...formData, username: event.target.value })}
                                                            autoComplete='off'
                                                            disabled
                                                        />
                                                    </SoftBox>
                                                </Grid>
                                                <Grid item xs={12} sm={12} lg={6}>
                                                    <SoftBox mb={2} justifyContent="center">
                                                        <SoftBox mb={1} ml={0.5}>
                                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                Kelurahan
                                                            </SoftTypography>
                                                        </SoftBox>
                                                        <SoftInput
                                                            type="text"
                                                            defaultValue={formData.nama_kel}
                                                            // onChange={(event) => setFormData({ ...formData, username: event.target.value })}
                                                            autoComplete='off'
                                                            disabled
                                                        />
                                                    </SoftBox>
                                                </Grid>
                                                <Grid item xs={12} sm={12} lg={6}>
                                                    <SoftBox mb={2} justifyContent="center">
                                                        <SoftBox mb={1} ml={0.5}>
                                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                Jenis APK
                                                            </SoftTypography>
                                                        </SoftBox>
                                                        <FormControl fullWidth>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={formData.jenis_apk}
                                                                label="Role"
                                                                onChange={(e) => setFormData({ ...formData, jenis_apk: e.target.value })}
                                                            >
                                                                {
                                                                    jenisApks.map((jenisApk) => (
                                                                        <MenuItem key={jenisApk.id} value={jenisApk.id}>{jenisApk.nama}</MenuItem>
                                                                    ))
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                    </SoftBox>
                                                </Grid>
                                                <Grid item xs={12} sm={12} lg={6}>
                                                    <SoftBox mb={2} justifyContent="center">
                                                        <SoftBox mb={1} ml={0.5}>
                                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                Dokumentasi
                                                            </SoftTypography>
                                                        </SoftBox>
                                                        <MuiFileInput
                                                            value={formData.src}
                                                            onChange={loadData}
                                                            multiple
                                                            fullWidth
                                                            inputProps={{ accept: "image/jpeg" }}
                                                        />
                                                    </SoftBox>
                                                </Grid>
                                                {formData.src.length > 0 &&
                                                    <Grid container spacing={2} py={2} justifyContent="center">
                                                        <Grid item xs={10} sx={{ textAlign: "center" }}>
                                                            <Accordion>
                                                                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMore />}>
                                                                    <Typography>Dokumentasi</Typography>
                                                                </AccordionSummary>
                                                                <AccordionDetails>
                                                                    <Grid container spacing={2} justifyContent='center'>
                                                                        {
                                                                            formData.src.length > 0 && (
                                                                                formData.src.map((gambar, index) => (
                                                                                    <Grid item xs={9} key={index} variant='outlined' component={Paper} mb={2}>
                                                                                        {/* <IconButton color='error' aria-label="delete" onClick={() => setFormData({ ...formData, src: formData.src.splice(index - 1, 1) })}>
                                                                            <Delete />
                                                                        </IconButton> */}
                                                                                        <img
                                                                                            src={URL.createObjectURL(gambar)}
                                                                                            srcSet={gambar}
                                                                                            alt={index}
                                                                                            loading="lazy"
                                                                                            width='100%'
                                                                                        />
                                                                                    </Grid>
                                                                                ))
                                                                            )
                                                                        }
                                                                    </Grid>
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        </Grid>
                                                    </Grid>
                                                }
                                                <Grid item xs={12} sm={12} lg={6}>
                                                    <Grid container justifyContent='flex-end'>
                                                        <Grid item>
                                                            <SoftBox mb={1} ml={0.5}>
                                                                <LoadingButton
                                                                    loading={loading}
                                                                    loadingPosition="start"
                                                                    startIcon={<Save />}
                                                                    variant="contained"
                                                                    type='submit'
                                                                >
                                                                    Save
                                                                </LoadingButton>
                                                            </SoftBox>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    )
                            }

                        </Card>
                    </Grid>
                </Grid>
            </SoftBox>
        </>
    )
}

export default Tambah