import { Divider, List, ListItem, ListItemText, Typography } from '@mui/material'

const DetailTim = ({data}) => {
    return (
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            <ListItem>
                <ListItemText primary={
                    <Typography variant='subtitle2'>
                        NIK
                    </Typography>
                } secondary={
                    <Typography variant='body2'>
                        {data.nik}
                    </Typography>
                } />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem>
                <ListItemText primary={
                    <Typography variant='subtitle2'>
                        Nama
                    </Typography>
                } secondary={
                    <Typography variant='body2'>
                        {data.nama}
                    </Typography>
                } />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem>
                <ListItemText primary={
                    <Typography variant='subtitle2'>
                        Kecamatan
                    </Typography>
                } secondary={
                    <Typography variant='body2'>
                        {data.nama_kec}
                    </Typography>
                } />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem>
                <ListItemText primary={
                    <Typography variant='subtitle2'>
                        Kelurahan
                    </Typography>
                } secondary={
                    <Typography variant='body2' component="span" sx={{ display: 'block' }} color="text.primary">
                        {data.nama_kel}
                    </Typography>
                } />
            </ListItem>
            <Divider variant="inset" component="li" />
        </List>
    )
}

export default DetailTim