import { ArrowBack, Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Autocomplete, Box, Card, CardContent, CardHeader, CircularProgress, Divider, Grid, IconButton, List, ListItem, ListItemText, ListSubheader, MenuItem, Select, TextField, Tooltip, Typography } from '@mui/material';
import axios from 'axios';
import Headerx from 'components/Header/Headerx';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import { CustomDescrypt } from 'global/func';
import { serverUrl } from 'global/variable';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import userStore from 'store/userStore';
import Swal from 'sweetalert2';

const TambahST = () => {
    const navigate = useNavigate();
    const encryptUser = localStorage.getItem('AuthPersist');
    const encryptUserJson = JSON.parse(encryptUser);
    const decryptedUser = encryptUserJson.state.user === null ? "" : JSON.parse(CustomDescrypt(encryptUserJson.state.user));
    const logOut = userStore((state) => state.logOut)

    const location = useLocation();
    const data = location.state;

    const [loading, setLoading] = useState(false)
    const [listDpt, setListDpts] = useState([])
    const [dpt, setDpt] = useState({})

    const [desas, setDesas] = useState([]);
    const [kecs, setKecs] = useState([]);
    const [desa, setDesa] = useState('');
    const [kec, setKec] = useState('');

    const getDesa = async (params) => {
        const data = await axios.get(serverUrl + '/desa/' + params);
        setDesas(data.data)
        // console.log(data);
    }

    // console.log(data);
    // const dpts  = await axios.get('/dpt');

    const reqDpt = (idKel) => {
        // const decodedUser = decodeToken(decryptedUser);
        // console.log(decodedUser.id_kel);
        setLoading(true)
        axios.post(
            serverUrl + '/dpt',
            {
                suara_tambahan: '',
                dtd_username: '',
                pertemuan_terbatas: '',
                bansos: '',
                tim: '',
                id_kel: idKel
            },
            {
                headers: {
                    'Authorization': `Bearer ${decryptedUser}`,
                    'content-type': 'multipart/form-data'
                }
            })
            .then((response) => {
                console.log(response);
                setListDpts(response.data)
                setLoading(false)
            })
            .catch((error) => {
                console.log(error);
                if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                    logOut();
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.response.status + ' ' + error.response.statusText
                    })
                }
                setLoading(false)
            })
    }

    const submit = (event) => {
        event.preventDefault();
        setLoading(true)
        // setFormData({...formData, agg})
        axios.post(
            serverUrl + '/thi_tim/suara_tambahan/update',
            {
                _method: 'PUT',
                nik: dpt.nik,
                suara_tambahan: data.id,
                suara_tambahan_nama: data.nama
            },
            {
                headers: {
                    'Authorization': `Bearer ${decryptedUser}`,
                    'content-type': 'multipart/form-data'
                }
            })
            .then((response) => {
                console.log(response);
                Swal.fire({
                    icon: 'success',
                    title: 'Berhasil!',
                    text: response.data.msg
                })
                navigate(-1)
                setLoading(false)
            })
            .catch((error) => {
                console.log(error);
                if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                    logOut();
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.response.data.messages.msg
                    })
                }
                setLoading(false)
            })
    }

    const handleChangeDesa = (event) => {
        if (event.target.value === '') {
            setDesa('')
        }
        else {
            setDesa(event.target.value)
            reqDpt(event.target.value)
        }
    };

    useEffect(() => {
        getKec()
    }, [])

    const getKec = async () => {
        const kec = await axios.get(serverUrl + '/kecamatan');

        setKecs(kec.data)
    }

    const handleChangeKec = (event) => {
        // setDesas([])
        if (event.target.value === '') {
            // setFormData({ ...formData, id_kec: '', id_kel: '', nama_kec: '', nama_kel: '' })
            setKec('')
            setDesa('')
        }
        else {
            setKec(event.target.value)
            getDesa(event.target.value)
            // let resultObject = kecamatans.find(x => x.id_kec === event.target.value)
            // setFormData({ ...formData, id_kec: event.target.value, nama_kec: resultObject.nama_kec })
            // event.target.value !== '' && getDesa(event.target.value)
        }
    };

    return (
        <>
            <Headerx title='Tambah Data' />
            <SoftBox mb={3}>
                <Grid container spacing={3} justifyContent='center' py={5}>
                    <Grid item xs={12} sm={12} md={9}>
                        <Card >
                            <CardHeader
                                title={
                                    <SoftTypography variant='h6'>Kembali</SoftTypography>
                                }
                                avatar={
                                    <Tooltip title="Kembali">
                                        <IconButton variant="outlined" onClick={() => navigate(-1)}><ArrowBack /></IconButton>
                                    </Tooltip>
                                }
                            />
                            {
                                loading ?
                                    (
                                        <Grid container spacing={3} py={5} justifyContent="center">
                                            <Grid item>
                                                <Box sx={{ display: 'flex' }}>
                                                    <CircularProgress />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    )
                                    :
                                    (
                                        <CardContent component='form' onSubmit={submit}>
                                            <Grid container spacing={3} justifyContent="center">
                                                <Grid item xs={12} sm={12} lg={6}>
                                                    <SoftBox mb={1} ml={0.5}>
                                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                            Pilih Kecamatan
                                                        </SoftTypography>
                                                    </SoftBox>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        defaultValue=''
                                                        value={kec}
                                                        label="Kecamatan"
                                                        onChange={handleChangeKec}
                                                        required
                                                    >
                                                        <MenuItem value=''><em>Pilih Desa</em></MenuItem>
                                                        {
                                                            kecs.map((kec) => (
                                                                <MenuItem key={kec.id_kec} value={kec.id_kec}>{kec.nama_kec}</MenuItem>
                                                            ))
                                                        }
                                                    </Select>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={3} justifyContent="center">
                                                <Grid item xs={12} sm={12} lg={6}>
                                                    <SoftBox mb={1} ml={0.5}>
                                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                            Pilih Kelurahan
                                                        </SoftTypography>
                                                    </SoftBox>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        defaultValue=''
                                                        value={desa}
                                                        label="Desa"
                                                        onChange={handleChangeDesa}
                                                        required
                                                    >
                                                        <MenuItem value=''><em>Pilih Desa</em></MenuItem>
                                                        {
                                                            desas.map((des) => (
                                                                <MenuItem key={des.id_desa} value={des.id_desa}>{des.nama_desa}</MenuItem>
                                                            ))
                                                        }
                                                    </Select>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={3} justifyContent="center">
                                                <Grid item xs={12} sm={12} lg={6}>
                                                    <SoftBox mb={1} ml={0.5}>
                                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                            NIK
                                                        </SoftTypography>
                                                    </SoftBox>
                                                    <Autocomplete
                                                        disablePortal
                                                        id="combo-box-demo"
                                                        options={listDpt}
                                                        fullWidth
                                                        getOptionLabel={(option) => option.nik + ' ' + option.nama}
                                                        onChange={(event, newValue) => {
                                                            newValue !== null && setDpt(newValue)
                                                        }}
                                                        onInputChange={(event, newInputValue) => {
                                                            newInputValue === "" && setDpt({})
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={3} py={3} justifyContent="center">

                                                <Grid item xs={12} sm={12} lg={6}>
                                                    <List sx={{ bgcolor: 'background.paper' }} subheader={
                                                        <ListSubheader component="div" id="nested-list-subheader">
                                                            Data DPT
                                                        </ListSubheader>
                                                    }>
                                                        <Divider />
                                                        <ListItem>
                                                            <ListItemText primary={
                                                                <Typography
                                                                    variant="body2"
                                                                    color="text.primary"
                                                                >
                                                                    NIK
                                                                </Typography>
                                                            }
                                                                secondary={
                                                                    <Typography
                                                                        variant="body2"
                                                                        color="text.primary"
                                                                    >
                                                                        {dpt.nik ? dpt.nik : '-'}
                                                                    </Typography>
                                                                } />
                                                        </ListItem>
                                                        <Divider />
                                                        <ListItem>
                                                            <ListItemText primary={
                                                                <Typography
                                                                    variant="body2"
                                                                    color="text.primary"
                                                                >
                                                                    No. KK
                                                                </Typography>
                                                            }
                                                                secondary={
                                                                    <Typography
                                                                        variant="body2"
                                                                        color="text.primary"
                                                                    >
                                                                        {dpt.no_kk ? dpt.no_kk : '-'}
                                                                    </Typography>
                                                                } />
                                                        </ListItem>
                                                        <Divider />
                                                        <ListItem>
                                                            <ListItemText primary={
                                                                <Typography
                                                                    variant="body2"
                                                                    color="text.primary"
                                                                >
                                                                    Nama
                                                                </Typography>
                                                            }
                                                                secondary={
                                                                    <Typography
                                                                        variant="body2"
                                                                        color="text.primary"
                                                                    >
                                                                        {dpt.nama ? dpt.nama : '-'}
                                                                    </Typography>
                                                                } />
                                                        </ListItem>
                                                        <Divider />
                                                        <ListItem>
                                                            <ListItemText
                                                                primary={
                                                                    <Typography
                                                                        variant="body2"
                                                                        color="text.primary"
                                                                    >
                                                                        Tempat/Tgl Lahir
                                                                    </Typography>
                                                                }
                                                                secondary={
                                                                    <Typography
                                                                        variant="body2"
                                                                        color="text.primary"
                                                                    >
                                                                        {dpt.tempat_lahir ? dpt.tempat_lahir + ', ' + dpt.tgl_lahir : '-'}
                                                                    </Typography>
                                                                }
                                                            />
                                                        </ListItem>
                                                        <Divider />
                                                        <ListItem>
                                                            <ListItemText primary={
                                                                <Typography
                                                                    variant="body2"
                                                                    color="text.primary"
                                                                >
                                                                    Alamat
                                                                </Typography>
                                                            }
                                                                secondary={
                                                                    <Typography
                                                                        variant="body2"
                                                                        color="text.primary"
                                                                    >
                                                                        {dpt.alamat ? `${dpt.alamat} RT. ${dpt.rt} RW. ${dpt.rw}, Kelurahan. ${dpt.nama_kel}, Kec. ${dpt.nama_kec}, Kab/Kota. ${dpt.nama_kab}` : '-'}
                                                                    </Typography>
                                                                } />
                                                        </ListItem>
                                                        <Divider />
                                                    </List>
                                                </Grid>
                                            </Grid>
                                            <Grid container justifyContent='center'>
                                                <Grid item xs={12} sm={12} lg={6}>
                                                    <Grid container justifyContent='flex-end'>
                                                        <Grid item>
                                                            <SoftBox mb={1} ml={0.5}>
                                                                <LoadingButton
                                                                    loading={loading}
                                                                    loadingPosition="start"
                                                                    startIcon={<Save />}
                                                                    variant="contained"
                                                                    type='submit'
                                                                >
                                                                    Save
                                                                </LoadingButton>
                                                            </SoftBox>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    )
                            }

                        </Card>
                    </Grid>
                </Grid>
            </SoftBox>
        </>
    )
}

export default TambahST