import { AddCircleOutline, Delete, People } from '@mui/icons-material'
import { Avatar, Button, Card, CardContent, Divider, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Typography } from '@mui/material'
import MiniStatisticsCard from 'components/Cards/StatisticsCards/MiniStatisticsCard'
import SoftBox from 'components/SoftBox'
import { CustomDescrypt } from 'global/func'
import React, { useEffect, useState } from 'react'
import { decodeToken } from 'react-jwt'
import { Link, Route, Routes } from 'react-router-dom'
import Swal from 'sweetalert2'
import Tambah from './Tambah'
import LoadingList from 'myComponent/LoadingList'
import Headerx from 'components/Header/Headerx'
import Detail from './Detail'
import { postDataWithResponse } from 'global/postFunc'
import { postDelete } from 'global/postFunc'
import userStore from 'store/userStore'

const Pendataan = () => {
    return (
        <Routes>
            <Route exact path='' element={<Data />} />
            <Route exact path='detail' element={<Detail />} />
            <Route exact path='tambah' element={<Tambah />} />
            {/* <Route exact path='edit' element={<Edit />} /> */}
        </Routes>
    )
}

const Data = () => {
    const encryptUser = localStorage.getItem('AuthPersist');
    const encryptUserJson = JSON.parse(encryptUser);
    const decryptedUser = encryptUserJson.state.user === null ? "" : JSON.parse(CustomDescrypt(encryptUserJson.state.user));
    const decodedUser = decodeToken(decryptedUser);
    const logOut = userStore((state) => state.logOut)

    const [loading, setLoading] = useState(true)
    const [tableData, setTableData] = useState([]);
    const [deleting, setDeleting] = useState(false);

    useEffect(() => {
        postDataWithResponse(
            '/vote-getter/dtd',
            {
                dtd_username: decodedUser.username,
            },
            decryptedUser,
            setLoading,
            setTableData,
            logOut
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleting])

    const deleteData = (nik, nama) => {
        console.log(nik);
        Swal.fire({
            title: 'Hapus data?',
            text: `Data ${nama} akan dihapus`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya, hapus!',
            showLoaderOnConfirm: true
        }).then((result) => {
            if (result.isConfirmed) {
                postDelete(
                    '/vote-getter/dtd/delete',
                    {
                        _method: 'DELETE',
                        nik: nik,
                    },
                    decryptedUser,
                    setLoading,
                    deleting,
                    setDeleting,
                    logOut
                )
            }
        })
    }

    return (
        <>
            <Headerx title='Pendataan DTD' />
            <SoftBox my={3}>
                <Grid container spacing={3} mb={3} justifyContent='center'>
                    <Grid item xs={12} lg={6}>
                        <MiniStatisticsCard
                            title={{ text: "Total Data" }}
                            count={tableData.length ? tableData.length : 0}
                            // percentage={{ color: "success", text: "+55%" }}
                            icon={{ color: "info", component: "paid" }}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={3} justifyContent='center'>
                    <Grid item xs={12} lg={6}>
                        <Card >
                            <Grid container justifyContent="space-between" p={2}>
                                <Grid item xs="auto">
                                    <Typography>
                                        Pendataan DTD
                                    </Typography>
                                </Grid>

                                <Grid item>
                                    <Button
                                        variant="contained"
                                        endIcon={<AddCircleOutline color='white' />}
                                        // onClick={() => handleOpenDialog2()}
                                        to="/vote-getter/dtd/tambah"
                                        component={Link}
                                    // disabled={tableData.length >= 10}
                                    >
                                        Tambah
                                    </Button>
                                </Grid>
                            </Grid>
                            {
                                loading ? (
                                    <>
                                        <LoadingList />
                                        <Divider />
                                        <LoadingList />
                                        <Divider />
                                        <LoadingList />
                                        <Divider />
                                        <LoadingList />
                                        <Divider />
                                        <LoadingList />
                                        <Divider />
                                        <LoadingList />
                                        <Divider />
                                        <LoadingList />
                                        <Divider />
                                        <LoadingList />
                                        <Divider />
                                        <LoadingList />
                                        <Divider />
                                        <LoadingList />
                                        <Divider />
                                    </>
                                )
                                    :
                                    tableData.length > 0 ?
                                        (
                                            <Grid container justifyContent="center" p={2}>
                                                <Grid item xl={12}>
                                                    <List sx={{ bgcolor: 'background.paper' }}>
                                                        {
                                                            tableData.map((data) => (
                                                                <React.Fragment key={data.nik}>
                                                                    <ListItem
                                                                        alignItems="flex-start"
                                                                        secondaryAction={
                                                                            <IconButton edge="end" aria-label="delete" onClick={() => deleteData(data.nik, data.nama)}>
                                                                                <Delete color='error' />
                                                                            </IconButton>
                                                                        }
                                                                    >
                                                                        <ListItemButton
                                                                            component={Link} to="/vote-getter/dtd/detail" state={data}
                                                                        >
                                                                            <ListItemAvatar>
                                                                                <Avatar>
                                                                                    <People />
                                                                                </Avatar>
                                                                            </ListItemAvatar>
                                                                            <ListItemText
                                                                                primary={
                                                                                    <Typography
                                                                                        variant="body2"
                                                                                        color="text.primary"
                                                                                    >
                                                                                        {data.nama}
                                                                                    </Typography>
                                                                                }
                                                                                secondary={
                                                                                    <Typography
                                                                                        variant="body2"
                                                                                        color="text.primary"
                                                                                    >
                                                                                        Kel. {data.nama_kel}, RT. {data.rt}, RW. {data.rw}, TPS. {data.nama_tps}
                                                                                    </Typography>
                                                                                }
                                                                            />
                                                                        </ListItemButton>
                                                                    </ListItem>
                                                                    <Divider />
                                                                </React.Fragment>
                                                            ))
                                                        }
                                                    </List>
                                                </Grid>
                                            </Grid>
                                        )
                                        :
                                        (
                                            <CardContent>
                                                <Typography variant="body2" color="text.secondary">
                                                    Tidak ada data
                                                </Typography>
                                            </CardContent>
                                        )

                            }
                        </Card>
                    </Grid>
                </Grid>
            </SoftBox>
        </>
    )
}

export default Pendataan