import { ArrowBack } from '@mui/icons-material'
import { Card, CardContent, CardHeader, Divider, Grid, IconButton, List, ListItem, ListItemText, Tooltip, Typography } from '@mui/material'
import axios from 'axios'
import Headerx from 'components/Header/Headerx'
import SoftBox from 'components/SoftBox'
import { CustomDescrypt } from 'global/func'
import { serverUrl } from 'global/variable'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import userStore from 'store/userStore'
import Swal from 'sweetalert2'

const Detail = () => {
    const encryptUser = localStorage.getItem('AuthPersist');
    const encryptUserJson = JSON.parse(encryptUser);
    const decryptedUser = encryptUserJson.state.user === null ? "" : JSON.parse(CustomDescrypt(encryptUserJson.state.user));
    const logOut = userStore((state) => state.logOut)

    const location = useLocation();
    const navigate = useNavigate();
    const data = location.state;

    const [gambar, setGambar] = useState([])

    useEffect(() => {
        console.log(data);
        reqGambar()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const reqGambar = () => {
        axios.post(
            serverUrl + '/dokumentasi_apk',
            {
                id_apk: data.id_apk
            },
            {
                headers: {
                    'Authorization': `Bearer ${decryptedUser.token}`,
                    'content-type': 'multipart/form-data'
                }
            })
            .then((response) => {
                console.log(response);
                setGambar(response.data)
                // setTableLoading(false)
            })
            .catch((error) => {
                if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                    logOut();
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.response.status + ' ' + error.response.statusText
                    })
                }
                // setTableLoading(false)
            })
    }

    return (
        <>
            <Headerx title="Detail" />
            <SoftBox mb={3}>
                <Grid container spacing={3} justifyContent='center' py={5}>
                    <Grid item xs={12} sm={12} md={9}>
                        <Card >
                            <CardHeader
                                title={
                                    <Typography variant='h6'>Kembali</Typography>
                                }
                                avatar={
                                    <Tooltip title="Kembali">
                                        <IconButton variant="outlined" onClick={() => navigate(-1)}><ArrowBack /></IconButton>
                                    </Tooltip>
                                }
                            // subheader="September 14, 2016"
                            />
                            <CardContent>
                                <Grid container spacing={2} justifyContent='center'>
                                    <Grid item xs={12}>
                                        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                            <ListItem>
                                                <ListItemText primary={
                                                    <Typography variant='subtitle2'>
                                                        Jenis APK
                                                    </Typography>
                                                } secondary={
                                                    <Typography variant='body2'>
                                                        {data.nama}
                                                    </Typography>
                                                } />
                                            </ListItem>
                                            <Divider variant="inset" component="li" />
                                        </List>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} justifyContent='center' pt={2}>
                                    <Grid item xs={12}>
                                        <Typography>
                                            Dokumentasi
                                        </Typography>
                                    </Grid>
                                    {
                                        gambar.length > 0 ? (
                                            gambar.map((gbr, index) => (
                                                <Grid item xs={12} key={index}>
                                                    <img
                                                        src={serverUrl + '/uploads/apk/' + data.id_apk + '/' + gbr}
                                                        srcSet={serverUrl + '/uploads/apk/' + data.id_apk + '/' + gbr}
                                                        alt={index}
                                                        loading="lazy"
                                                        width='100%'
                                                    />
                                                </Grid>
                                            ))
                                        )
                                            :
                                            (
                                                <CardContent>
                                                    <Typography variant="body2" color="text.secondary">
                                                        Tidak ada data
                                                    </Typography>
                                                </CardContent>
                                            )
                                    }
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </SoftBox>
        </>
    )
}

export default Detail