import { Diversity3, Flag, Home, Menu, People } from "@mui/icons-material"

const KorkelRoutes = [
    {
        type: "route",
        name: "Dashboard",
        key: [''],
        route: "/korkel",
        icon: <Home size="12px" color="warning" />,
        noCollapse: true,
    },
    {
        type: "route",
        name: "Pendataan DTD",
        key: ['dtd'],
        route: "/korkel/dtd",
        icon: <Menu size="12px" color="info" />,
        noCollapse: true,
    },
    {
        type: "route",
        name: "Pertemuan Terbatas",
        key: ['pt'],
        route: "/korkel/pt",
        icon: <Diversity3 size="12px" color="secondary" />,
        noCollapse: true,
    },
    {
        type: "route",
        name: "APK",
        key: ['apk'],
        route: "/korkel/apk",
        icon: <Flag size="12px" color="success" />,
        noCollapse: true,
    },
    {
        type: "route",
        name: "Vote Getter",
        key: ['vote-getter'],
        route: "/korkel/vote-getter",
        icon: <People size="12px" color="error" />,
        noCollapse: true,
    },
]

export default KorkelRoutes