import { ArrowBack, ExpandMore, Save } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Card, CardContent, CardHeader, CircularProgress, Divider, Grid, IconButton, List, ListItem, ListItemText, ListSubheader, Paper, TextField, Tooltip, Typography } from '@mui/material'
import Headerx from 'components/Header/Headerx'
import SoftBox from 'components/SoftBox'
import SoftTypography from 'components/SoftTypography'
import { CustomDescrypt } from 'global/func'
import { postDataWithResponse } from 'global/postFunc'
import { postSubmit } from 'global/postFunc'
import { MuiFileInput } from 'mui-file-input'
import React, { useEffect, useState } from 'react'
import { decodeToken } from 'react-jwt'
import { useNavigate } from 'react-router-dom'
import userStore from 'store/userStore'

const Tambah = () => {
    const navigate = useNavigate();
    const encryptUser = localStorage.getItem('AuthPersist');
    const encryptUserJson = JSON.parse(encryptUser);
    const decryptedUser = encryptUserJson.state.user === null ? "" : JSON.parse(CustomDescrypt(encryptUserJson.state.user));
    const logOut = userStore((state) => state.logOut)

    const [loading, setLoading] = useState(false)
    const [listDpt, setListDpts] = useState([])
    const [dpt, setDpt] = useState({})
    const [src, setSrc] = useState()

    // const [formData, setFormData] = useState({
    //     nik: '',
    // })

    useEffect(() => {
        reqDpt()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        // console.log(formData);
        console.log(dpt);
    }, [dpt])

    // const dpts  = await axios.get('/dpt');

    const reqDpt = () => {
        const decodedUser = decodeToken(decryptedUser);
        // console.log(decodedUser.id_kel);
        postDataWithResponse(
            '/dpt',
            {
                id_kel: decodedUser.id_kel,
                dtd_username: '',
                tim: ''
            },
            decryptedUser,
            setLoading,
            setListDpts,
            logOut
        )
    }

    const submit = (event) => {
        event.preventDefault();
        const decodedUser = decodeToken(decryptedUser);
        postSubmit(
            '/vote-getter/dtd/update',
            {
                _method: 'PUT',
                nik: dpt.nik,
                dtd_username: decodedUser.username,
                dtd_nama: decodedUser.nama,
                src: src
            },
            decryptedUser,
            setLoading,
            navigate,
            logOut
        )
    }

    const loadData = (newValue) => {
        setSrc(newValue)
        console.log(newValue);
    }

    return (
        <>
            <Headerx title='Tambah Data' />
            <SoftBox mb={3}>
                <Grid container spacing={3} justifyContent='center' py={5}>
                    <Grid item xs={12} sm={12} md={9}>
                        <Card >
                            <CardHeader
                                title={
                                    <SoftTypography variant='h6'>Kembali</SoftTypography>
                                }
                                avatar={
                                    <Tooltip title="Kembali">
                                        <IconButton variant="outlined" onClick={() => navigate(-1)}><ArrowBack /></IconButton>
                                    </Tooltip>
                                }
                            />
                            {
                                loading ?
                                    (
                                        <Grid container spacing={3} py={5} justifyContent="center">
                                            <Grid item>
                                                <Box sx={{ display: 'flex' }}>
                                                    <CircularProgress />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    )
                                    :
                                    (
                                        <CardContent component='form' onSubmit={submit}>
                                            <Grid container spacing={3} justifyContent="center">
                                                <Grid item xs={12} sm={12} lg={6}>
                                                    <SoftBox mb={1} ml={0.5}>
                                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                            Nama
                                                        </SoftTypography>
                                                    </SoftBox>
                                                    <Autocomplete
                                                        disablePortal
                                                        id="combo-box-demo"
                                                        options={listDpt}
                                                        fullWidth
                                                        getOptionLabel={(option) => option.nik + ' ' + option.nama}
                                                        onChange={(event, newValue) => {
                                                            newValue !== null && setDpt(newValue)
                                                        }}
                                                        onInputChange={(event, newInputValue) => {
                                                            newInputValue === "" && setDpt({})
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={3} pt={3} justifyContent="center">

                                                <Grid item xs={12} sm={12} lg={6}>
                                                    <List sx={{ bgcolor: 'background.paper' }} subheader={
                                                        <ListSubheader component="div" id="nested-list-subheader">
                                                            Data DPT
                                                        </ListSubheader>
                                                    }>
                                                        <Divider />
                                                        <ListItem>
                                                            <ListItemText primary={
                                                                <Typography
                                                                    variant="body2"
                                                                    color="text.primary"
                                                                >
                                                                    NIK
                                                                </Typography>
                                                            }
                                                                secondary={
                                                                    <Typography
                                                                        variant="body2"
                                                                        color="text.primary"
                                                                    >
                                                                        {dpt.nik ? dpt.nik : '-'}
                                                                    </Typography>
                                                                } />
                                                        </ListItem>
                                                        <Divider />
                                                        <ListItem>
                                                            <ListItemText primary={
                                                                <Typography
                                                                    variant="body2"
                                                                    color="text.primary"
                                                                >
                                                                    No. KK
                                                                </Typography>
                                                            }
                                                                secondary={
                                                                    <Typography
                                                                        variant="body2"
                                                                        color="text.primary"
                                                                    >
                                                                        {dpt.no_kk ? dpt.no_kk : '-'}
                                                                    </Typography>
                                                                } />
                                                        </ListItem>
                                                        <Divider />
                                                        <ListItem>
                                                            <ListItemText primary={
                                                                <Typography
                                                                    variant="body2"
                                                                    color="text.primary"
                                                                >
                                                                    Nama
                                                                </Typography>
                                                            }
                                                                secondary={
                                                                    <Typography
                                                                        variant="body2"
                                                                        color="text.primary"
                                                                    >
                                                                        {dpt.nama ? dpt.nama : '-'}
                                                                    </Typography>
                                                                } />
                                                        </ListItem>
                                                        <Divider />
                                                        <ListItem>
                                                            <ListItemText
                                                                primary={
                                                                    <Typography
                                                                        variant="body2"
                                                                        color="text.primary"
                                                                    >
                                                                        Tempat/Tgl Lahir
                                                                    </Typography>
                                                                }
                                                                secondary={
                                                                    <Typography
                                                                        variant="body2"
                                                                        color="text.primary"
                                                                    >
                                                                        {dpt.tempat_lahir ? dpt.tempat_lahir + ', ' + dpt.tgl_lahir : '-'}
                                                                    </Typography>
                                                                }
                                                            />
                                                        </ListItem>
                                                        <Divider />
                                                        <ListItem>
                                                            <ListItemText
                                                                primary={
                                                                    <Typography
                                                                        variant="body2"
                                                                        color="text.primary"
                                                                    >
                                                                        Status Kawin
                                                                    </Typography>
                                                                }
                                                                secondary={
                                                                    <Typography
                                                                        variant="body2"
                                                                        color="text.primary"
                                                                    >
                                                                        {
                                                                            dpt.stts_kawin
                                                                        }
                                                                    </Typography>
                                                                }
                                                            />
                                                        </ListItem>
                                                        <Divider />
                                                        <ListItem>
                                                            <ListItemText
                                                                primary={
                                                                    <Typography
                                                                        variant="body2"
                                                                        color="text.primary"
                                                                    >
                                                                        Jenis Kelamin
                                                                    </Typography>
                                                                }
                                                                secondary={
                                                                    <Typography
                                                                        variant="body2"
                                                                        color="text.primary"
                                                                    >
                                                                        {dpt.jenkel === 'L' ? 'Laki-laki' : 'Perempuan'}
                                                                    </Typography>
                                                                }
                                                            />
                                                        </ListItem>
                                                        <Divider />
                                                        <ListItem>
                                                            <ListItemText
                                                                primary={
                                                                    <Typography
                                                                        variant="body2"
                                                                        color="text.primary"
                                                                    >
                                                                        Alamat
                                                                    </Typography>
                                                                }
                                                                secondary={
                                                                    <Typography
                                                                        variant="body2"
                                                                        color="text.primary"
                                                                    >
                                                                        {dpt.alamat ? `${dpt.alamat} RT. ${dpt.rt} RW. ${dpt.rw}, Kel. ${dpt.nama_kel}, Kec. ${dpt.nama_kec}, Kota. ${dpt.nama_kab}` : '-'}
                                                                    </Typography>
                                                                } />
                                                        </ListItem>
                                                        <Divider />
                                                        <ListItem>
                                                            <ListItemText
                                                                primary={
                                                                    <Typography
                                                                        variant="body2"
                                                                        color="text.primary"
                                                                    >
                                                                        RT / RW
                                                                    </Typography>
                                                                }
                                                                secondary={
                                                                    <Typography
                                                                        variant="body2"
                                                                        color="text.primary"
                                                                    >
                                                                        RT. {dpt.rt}, RW. {dpt.rw}
                                                                    </Typography>
                                                                } />
                                                        </ListItem>
                                                        <Divider />
                                                        <ListItem>
                                                            <ListItemText primary={
                                                                <Typography
                                                                    variant="body2"
                                                                    color="text.primary"
                                                                >
                                                                    TPS
                                                                </Typography>
                                                            }
                                                                secondary={
                                                                    <Typography
                                                                        variant="body2"
                                                                        color="text.primary"
                                                                    >
                                                                        {dpt.nama_tps}
                                                                    </Typography>
                                                                } />
                                                        </ListItem>
                                                        <Divider />
                                                    </List>
                                                </Grid>
                                            </Grid>
                                            <Grid container justifyContent="center">
                                                <Grid item xs={12} sm={12} lg={6}>
                                                    <SoftBox mb={2} justifyContent="center">
                                                        <SoftBox mb={1} ml={0.5}>
                                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                Dokumentasi
                                                            </SoftTypography>
                                                        </SoftBox>
                                                        <MuiFileInput
                                                            value={src}
                                                            onChange={loadData}
                                                            // multiple
                                                            fullWidth
                                                            inputProps={{
                                                                accept: "image/jpg"
                                                            }}
                                                        // required
                                                        />
                                                    </SoftBox>
                                                </Grid>
                                            </Grid>
                                            {src != null &&
                                                <Grid container spacing={2} justifyContent="center" pb={3}>
                                                    <Grid item xs={10} sx={{ textAlign: "center" }}>
                                                        <Accordion>
                                                            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMore />}>
                                                                <Typography>Dokumentasi</Typography>
                                                            </AccordionSummary>
                                                            <AccordionDetails>
                                                                <Grid container spacing={2} justifyContent='center'>
                                                                    <Grid item xs={9} variant='outlined' component={Paper} mb={2}>
                                                                        <img
                                                                            src={URL.createObjectURL(src)}
                                                                            // srcSet={src}
                                                                            alt='Dokumentasi'
                                                                            loading="lazy"
                                                                            width='100%'
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    </Grid>
                                                </Grid>
                                            }
                                            <Grid container justifyContent='center' pt={3}>
                                                <Grid item xs={10}>
                                                    <Grid container justifyContent='flex-end'>
                                                        <Grid item>
                                                            <SoftBox mb={1} ml={0.5}>
                                                                <LoadingButton
                                                                    loading={loading}
                                                                    loadingPosition="start"
                                                                    startIcon={<Save />}
                                                                    variant="contained"
                                                                    type='submit'
                                                                >
                                                                    Save
                                                                </LoadingButton>
                                                            </SoftBox>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    )
                            }

                        </Card>
                    </Grid>
                </Grid>
            </SoftBox>
        </>
    )
}

export default Tambah