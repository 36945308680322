import { ArrowBack } from '@mui/icons-material';
import { Card, CardContent, CardHeader, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import Headerx from 'components/Header/Headerx';
import SoftBox from 'components/SoftBox';
import DetailTim from 'myComponent/DetailTim';
import { useLocation, useNavigate } from 'react-router-dom';

const Detail = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const data = location.state;

    return (
        <>
            <Headerx title='Admin' />
            <SoftBox mb={3}>
                <Grid container spacing={3} justifyContent='center' py={5}>
                    <Grid item xs={12} sm={12} md={9}>
                        <Card>
                            <CardHeader
                                title={
                                    <Typography variant='h6'>Kembali</Typography>
                                }
                                avatar={
                                    <Tooltip title="Kembali">
                                        <IconButton variant="outlined" onClick={() => navigate(-1)}><ArrowBack /></IconButton>
                                    </Tooltip>
                                }
                            // subheader="September 14, 2016"
                            />
                            <CardContent>
                                <Grid container spacing={2} justifyContent='center'>
                                    <Grid item xs={12}>
                                        <DetailTim data={data} />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </SoftBox>
        </>
    )
}

export default Detail